import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import About from '../../components/about';
import Carousel from '../../components/carousel';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout locale="pt-BR">
      <SEO lang="pt-BR" title="Home" />
      <Carousel locale="pt-BR" />
      <div className="intro">
        <About locale="pt-BR" />
        <div className="contact">
          <Img fluid={data.aboutImage.childImageSharp.fluid} />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
